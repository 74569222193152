body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dfe4ea;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wellStyles {
  max-width: 400;
  margin: 0 auto 10px;
}

.card-body {
  margin: auto;
}

.page {
  font-size: 10px;
}

.authHeader {
  display: flex;
  flex-direction: column;
  min-height: 20px;
  font-size: 40px;
  font-weight: bold;
  margin-top: 40px;
  margin-left: 470px;
  color: rgb(36, 36, 53);
  padding-block-start: 1px;
  padding-left: 3%;
  padding-block-end: 5px;
  text-shadow: 1px 1px 3px;
}

.signUpForm {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  width: 500px;
  margin-left: 30%;
  margin-top: 55px;
  border-radius: 5px;
  background-color: #dbe2ec;
  padding-top: 1%;
  padding-left: 4%;
  padding-right: 5%;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.322),
    0 40px 40px 0 rgba(0, 0, 0, 0.199);
}

.login-form {
  display: flex;
  flex-direction: column;
  min-height: 350px;
  width: 500px;
  margin-left: 30%;
  margin-top: 55px;
  border-radius: 5px;
  background-color: #dbe2ec;
  padding-top: 1%;
  padding-left: 4%;
  padding-right: 5%;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.322),
    0 40px 40px 0 rgba(0, 0, 0, 0.199);
}

.addRecipientForm {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  width: 500px;
  margin-left: 30%;
  margin-top: 55px;
  border-radius: 5px;
  background-color: #dbe2ec;
  padding-top: 1%;
  padding-left: 4%;
  padding-right: 5%;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.322),
    0 40px 40px 0 rgba(0, 0, 0, 0.199);
}

.addRecipient-form-control {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 500px;
  margin-left: 30%;
  margin-top: 55px;
  border-radius: 5px;
  background-color: #dbe2ec;
  padding-top: 1%;
  padding-left: 4%;
  padding-right: 5%;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.322),
    0 40px 40px 0 rgba(0, 0, 0, 0.199);
}

.text-form-control {
  text-align: center;
}

.select-control {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 36px;
  width: 100%;
}

.password-form-control {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 36px;
  width: 100%;
}

.sms-form-control {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 500px;
  margin-left: 30%;
  margin-top: 55px;
  border-radius: 5px;
  background-color: #dbe2ec;
  padding-top: 1%;
  padding-left: 4%;
  padding-right: 5%;
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.322),
    0 40px 40px 0 rgba(0, 0, 0, 0.199);
}
.sms-input-form-control {
  height: 36px;
  width: 100%;
  text-align: center;
  border-radius: 3px;
}

.sms-center-form {
  margin-top: 5%;
}

.password-center-control {
  margin-top: 5%;
}

.button-Password {
  margin-top: 10%;
}

.logout {
  flex-direction: column;
  min-height: 45px;
  width: 100px;
  margin-left: 85%;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #dbe2ec;

  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.322),
    0 40px 40px 0 rgba(0, 0, 0, 0.199);
}

.navbar-default .navbar-brand {
  color: white;
  font-weight: 600;
}

.navbar-default {
  background: #34495e;
  margin-bottom: 0;
}

.navbar-default .navbar-nav > li > a {
  color: white;
  font-weight: 600;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.serial-number {
  display: block;
  width: 50%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.serial-number-option {
  margin: 15px;
}

.number-option {
  margin-bottom: 10px;
}

.error-serial-number {
  border: 1px solid #fd0000;
}

.button-qr-print {
  width: 100%;
}

.center-qr-code {
  margin: auto;
  width: 50%;
  padding: 10px;
}
.value-qr-code {
  width: 128px;
}

.qr-code-print {
  margin: 50px;
}

.user-list-form-control {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: 20%;
  margin-top: 3px;
  border-radius: 5px;
  background-color: #d1d1dac7;
}

.image-container {
  width: 70%;
  height: 70%;
}

.image-form-control {
  width: 128px;
  height: 128px;
  margin-top: 5px;
  margin-left: 20px;
}

.button-form {
  margin-top: 6%;
  margin-left: 5%;
}

.serial-number {
  margin-left: 10px;
}

.bold-serial-number {
  color: #ff0000;
  font-weight: bold;
}
.language-button-form {
  margin-left: 88%;
  margin-top: 2%;
}

.table-custom-inventory {
  width: 90%;
  margin-left: 5%;
}

.add-inventory-button {
  margin-left: 96%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.invntory-selection-custom {
  background: #cbe00c;
}

.close-btn-text {
  margin-bottom: 10px;
}

.custom-count-text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.custom-product-counts {
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 10px;
  font-size: 18px;
}

.custom-product-search {
  margin-top: 25px;
}

.product-item-modal-row {
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #bbbdbe;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px;
}

.cunstom-category_perm {
  text-align: center;
}

div.custom-container {
  background-color: #242424;
}

svg.custom .node circle {
  fill: #f3f3ff;
  stroke: #2593b8;
  stroke-width: 1.5px;
}

svg.custom .node text {
  font-size: 14px;
  background-color: rgb(209, 198, 198);
  fill: #24069b;
  text-shadow: 0 1px 4px black;
  margin-top: 15px;
}

svg.custom .node {
  cursor: pointer;
}

svg.custom path.link {
  fill: none;
  stroke: #2593b8;
  stroke-width: 1.5px;
}

.custom-users {
  margin-left: 10%;
}

#modal-products-to-recipients {
  width: 80%;
}

.table-recipient {
  cursor: pointer;
}

.your-dialog-classname {
  width: 90%;
}

.link-to-recpient {
  cursor: pointer;
}

.search-recipient {
  width: 57%;
  margin-left: 23%;
  margin-bottom: 30px;
}
